<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio sondaggio</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div class="page_content">
                <div class="event_container">
                    <form @submit.prevent="compilaSondaggio()">
                        <div v-for="(domanda, index) in formFields" :key="index" class="field">
                            <div class="field_title">
                                <span>
                                    {{ domanda.label }} {{ domanda.position }}
                                    <ion-text v-if="domanda.required === '1'" color="danger"><strong>*</strong></ion-text>
                                </span>
                            </div>
                            <div class="value">
                                <ion-input v-if="domanda.type === '1'" type="text" v-model="FormData[domanda.id]"> </ion-input>

                                <ion-textarea v-if="domanda.type === '3'" v-model="FormData[domanda.id]"> </ion-textarea>

                                <ion-datetime
                                    v-if="domanda.type === '11'"
                                    name="date_start"
                                    display-format="DD/MM/YYYY"
                                    picker-format="DD/MM/YYYY"
                                    min="1900"
                                    max="2099"
                                    slot="end"
                                    doneText="OK"
                                    cancelText="Annulla"
                                    v-model="FormData[domanda.id]"
                                ></ion-datetime>

                                <ion-select
                                    v-if="domanda.type === '5'"
                                    :name="domanda.key"
                                    interface="action-sheet"
                                    cancel-text="Annulla"
                                    ok-text="Conferma"
                                    v-model="FormData[domanda.id]"
                                >
                                    <ion-select-option v-for="(option, index) in domanda.options" :key="index" :value="option.value">
                                        {{ `${option.label}` }}
                                    </ion-select-option>
                                </ion-select>

                                <!-- <ion-radio-group v-if="domanda.type === '7'" @ionChange="setUsaBancaOre($event)"> -->
                                <ion-radio-group
                                    v-if="domanda.type === '7'"
                                    :value="domanda.options[0].value"
                                    @ionChange="setRadioRisposta($event, domanda.id)"
                                    v-model="FormData[domanda.id]"
                                >
                                    <ion-item v-for="(option, index) in domanda.options" :key="index" lines="none" class="ion-no-padding">
                                        <ion-label>{{ `${option.label}` }}</ion-label>
                                        <ion-radio slot="start" :value="option.value"></ion-radio>
                                    </ion-item>
                                </ion-radio-group>
                            </div>
                        </div>
                        <div class="submit">
                            <button type="submit" class="btn_risposte">
                                Invia risposte
                            </button>
                        </div>
                        <!--                         <div>
                            {{ domande }}
                        </div> -->
                    </form>
                    <!--                     <div class="customer">
                        {{ event.events_calendar_title }}
                    </div>
                    <div class="image_container">
                        <div v-if="event.events_calendar_photo" class="photo">
                            <ion-img :src="setImageUrl(event.events_calendar_photo)"></ion-img>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    IonTextarea,
    IonText,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonRadio,
    IonItem,
    IonRadioGroup,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import { defineComponent, ref, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiSondaggi from "@/services/sondaggi";

export default defineComponent({
    name: "SondaggioDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonSpinner,
        IonIcon,
        IonButton,
        IonInput,
        IonTextarea,
        IonText,
        IonDatetime,
        IonSelect,
        IonSelectOption,
        IonLabel,
        IonRadio,
        IonItem,
        IonRadioGroup,
    },
    setup(props, context) {
        //console.log(props.data);
        const sondaggio = ref([]);
        sondaggio.value = { ...props.data };
        //console.log(sondaggio.value);

        //Contenitore v-model del form
        const FormData = ref({});

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const domande = ref([]);
        const loading = ref(false);
        const formFields = ref([]);

        async function loadDomandeRisposte(domandaId, domanda, loading) {
            if (!loading.value) loading.value = true;
            if (domanda.sondaggi_domande_tipologia_value === "Risposta singola" || domanda.sondaggi_domande_tipologia_value === "Risposta singola - radio") {
                try {
                    const risposte = await apiSondaggi.getDomandeRisposte(domandaId);
                    //console.log(risposte);
                    return risposte;
                } catch (error) {
                    console.error(error);
                }
            }
            loading.value = false;
            return [];
        }
        /**
         * ! Load domande for the viewed sondaggio
         */
        async function loadDomande() {
            loading.value = true;
            try {
                domande.value = await apiSondaggi.getSondaggioDomande(sondaggio.value["sondaggi_id"]);
                if (domande.value.length > 0) {
                    domande.value.forEach((domanda) => {
                        const field = {
                            id: domanda.sondaggi_domande_id,
                            position: domanda.sondaggi_domande_ordine,
                            key: domanda.sondaggi_domande_domanda.replace(/\s/g, ""),
                            label: domanda.sondaggi_domande_domanda,
                            type: domanda.sondaggi_domande_tipologia,
                            required: domanda.sondaggi_domande_obbligatorio,
                            options: [],
                        };
                        //se è risposta singola (select) o risposta singola (radio) avrò array options popolato
                        const possibiliRisposte = loadDomandeRisposte(domanda.sondaggi_domande_id, domanda, loading).then((response) => {
                            if (response.length > 0) {
                                response.forEach((risposta) => {
                                    field.options.push({
                                        label: risposta.sondaggi_domande_risposte_risposta,
                                        value: risposta.sondaggi_domande_risposte_id,
                                    });
                                });
                            }
                            formFields.value.push(field);
                            /* console.log(formFields.value);
                            console.log(FormData.value); */
                            formFields.value.sort(function(a, b) {
                                return parseFloat(a.position) - parseFloat(b.position);
                            });
                        });
                    });
                    //loading.value = false;
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle domande", "toast_danger");
            } finally {
                //loading.value = false;
            }
        }

        /**
         * Set richiesta.usa_banca_ore value based on selected radio
         */
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            FormData.value[reference] = value;
        }

        function compilaSondaggio() {
            /* console.log(formFields.value);
            console.log(FormData.value);
            console.log(Object.keys(FormData.value).length); */
            if (Object.keys(FormData.value).length === 0) {
                openToast("Devi compilare almeno le risposte obbligatorie", "toast_danger");
                return;
            }
            console.log("submitting...");
            //Controllo se ho le risposte obbligatorie
            formFields.value.forEach((domanda) => {
                //console.log(domanda.id);
                if (domanda.required === "1" && !FormData.value[domanda.id]) {
                    console.log(`La domanda ${domanda.label} è obbligatoria ed è senza risposta`);
                    openToast("Non hai risposto a tutte le domande obbligatorie", "toast_danger");
                    return;
                }
            });
            console.log(FormData.value);
        }

        onMounted(() => {
            loadDomande();
        });

        return {
            loading,
            dateFormat,
            dateFormatWithMinutes,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            domande,
            FormData,
            compilaSondaggio,
            formFields,
            setRadioRisposta,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.event_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.event_container .customer {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .image_container {
    margin-bottom: 16px;
}
.event_container .field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.event_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.event_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

/** lead standard */
.lead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

.photo {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Form styles */
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

.btn_risposte {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
</style>
