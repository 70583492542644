
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonSpinner,
    IonIcon,
    //IonImg,
    modalController,
    menuController,
} from "@ionic/vue";
import { ref, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { refresh, menu, checkmarkCircle, calendar, arrowForward } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiSondaggi from "@/services/sondaggi";
import apiEventi from "@/services/eventi";

import EventDetail from "@/components/event/EventDetail.vue";
import SondaggioDetail from "@/components/sondaggi/SondaggioDetail.vue";

export default {
    name: "Confindustria",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
        //IonImg,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const loading = ref(false);
        const prenotazioni = ref([]);
        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * ! Load all events_calendar
         */
        const loadingEvents = ref(false);
        const events = ref([]);
        async function loadEvents() {
            loadingEvents.value = true;
            try {
                events.value = await apiEventi.getEvents();
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli eventi", "toast_danger");
            }
            loadingEvents.value = false;
        }
        loadEvents();

        function setImageUrl(path) {
            //console.log(path);
            return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
        }

        /**
         * ! Open event detail page
         */
        async function openEventDetail(event) {
            console.log(event);
            const modal = await modalController.create({
                component: EventDetail,
                componentProps: {
                    data: event,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    console.log(detail);
                    //Cerco evento appena modificataìo (detail non è undefined se ho modificato evento, ho chiuso il form, aggiornato il dettaglio per vedere i dati modificati e poi chiuso anche il dettaglio)
                    /*                     const editedPrenotazione = detail.data;
                    const editedIndex = prenotazioni.value.findIndex(
                        (prenotazione) => prenotazione.prenotazioni_automezzi_id == editedPrenotazione.prenotazioni_automezzi_id
                    );
                    if (editedIndex && editedPrenotazione) {
                        prenotazioni.value[editedIndex] = editedPrenotazione;
                    } */
                }
            });
            return modal.present();
        }

        /**
         * ! Load all sondaggi
         */
        const sondaggi = ref([]);
        async function loadSondaggi() {
            loading.value = true;
            try {
                sondaggi.value = await apiSondaggi.getSondaggi();
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei sondaggi", "toast_danger");
            }
            loading.value = false;
        }
        loadSondaggi();

        /**
         * ! Limit sondaggio title to 35 charactes
         */
        function setShortSondaggioTitle(title) {
            if (!title) {
                return "-";
            } else {
                const trimmedString = title.length > 35 ? title.substring(0, 32) + "..." : title;
                return trimmedString;
            }
        }

        /**
         * ! Open sondaggio detail page
         */
        async function openSodaggioDetail(sondaggio) {
            console.log(sondaggio);
            const modal = await modalController.create({
                component: SondaggioDetail,
                componentProps: {
                    data: sondaggio,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    console.log(detail);
                    //Cerco evento appena modificataìo (detail non è undefined se ho modificato evento, ho chiuso il form, aggiornato il dettaglio per vedere i dati modificati e poi chiuso anche il dettaglio)
                    /*                     const editedPrenotazione = detail.data;
                    const editedIndex = prenotazioni.value.findIndex(
                        (prenotazione) => prenotazione.prenotazioni_automezzi_id == editedPrenotazione.prenotazioni_automezzi_id
                    );
                    if (editedIndex && editedPrenotazione) {
                        prenotazioni.value[editedIndex] = editedPrenotazione;
                    } */
                }
            });
            return modal.present();
        }

        function refreshData() {
            loadEvents();
            loadSondaggi();
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            prenotazioni,
            menu,
            openMenu,
            //Nuovi campi
            loadSondaggi,
            sondaggi,
            openSodaggioDetail,
            setShortSondaggioTitle,
            checkmarkCircle,
            arrowForward,
            //Nuovi campi
            events,
            setImageUrl,
            calendar,
            openEventDetail,
            refreshData,
        };
    },
};
